<template>
    <div class="page_wrapper reg_form">
        <div class="page_labels mb-7">
            <h1 class="mb-2">
                Придумайте свой пароль
            </h1>
            <p>
                Придумайте надежный пароль для входа в ваш аккаунт
            </p>
        </div>
        <a-form-model
            ref="authForm"
            :model="form"
            layout="vertical"
            autocomplete="off"
            :rules="rules">
            <div class="grid_col_2">
                <a-form-model-item
                    ref="password"
                    label="Пароль"
                    prop="password">
                    <a-input-password
                        v-model="form.password"
                        autocomplete="off"
                        placeholder="Введите пароль"
                        size="large"/>
                </a-form-model-item>
                <a-form-model-item
                    ref="password_confirm"
                    label="Повторите пароль"
                    prop="password_confirm">
                    <a-input-password
                        v-model="form.password_confirm"
                        autocomplete="off"
                        placeholder="Повторите пароль"
                        size="large"/>
                </a-form-model-item>
            </div>
            <div class="buttons_grid">
                <a-button 
                    size="large"
                    @click="$store.commit('user/SET_REG_STEP', 2)">
                    Назад
                </a-button>
                <a-button
                    class="button_next"
                    type="primary"
                    ghost
                    :loading="loading"
                    size="large"
                    @click="formSubmit()">
                    Далее
                </a-button>
            </div>
        </a-form-model>
    </div>
</template>

<script>
export default {
    props: {
        regForm: {
            type: Object,
            default: () => {}
        },
        resetRegForm: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        let validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('field_required')));
            } else if (value !== this.form.password) {
                callback(new Error("Пароли не совпадают"));
            } else {
                callback();
            }
        };
        return {
            loading: false,
            form: {
                password: '',
                password_confirm: ''
            },
            rules: {
                password: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { min: 8, message: this.$t('required_sym', { sym: 8 }), trigger: 'change' }
                ],
                password_confirm: [
                    { validator: validatePass, trigger: 'change' },
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { min: 8, message: this.$t('required_sym', { sym: 8 }), trigger: 'change' }
                ]
            }
        }
    },
    methods: {
        formSubmit() {
            this.$refs.authForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const formData = {
                            ...this.regForm,
                            ...this.form
                        }
                        const { data } = await this.$http.post('/users/register/', formData)
                        if(data) {
                            localStorage.setItem('user', JSON.stringify(data))
                            this.$store.commit('user/SET_USER', data)
                            this.resetRegForm()
                            this.$store.commit('user/SET_REG_STEP', 5)
                        }
                    } catch(error) {
                        if(error.message) {
                            this.$message.error(error.message, 4)
                        }
                        console.log(error)
                    } finally {
                        this.loading = false
                    }
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.grid_col_2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}
.password_text{
    font-size: 13px;
}
.reg_form{
    @media (max-height: 750px) {
        padding-top: 110px;
    }
}

.buttons_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    @media (max-width: 430px) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.button_next {
    grid-column: span 2 / span 2;
    @media (max-width: 430px) {
        grid-column: span 1 / span 1;
    }
}
</style>